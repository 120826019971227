import React from "react"
import {graphql} from "gatsby"
import {Consumer} from "src/utils/contexts"

import SEO from "src/components/Element/seo"
import PostArticle from "src/components/Domain/Post/post_article"

const ProjectTemplate = ({data, pageContext, location}) => {
    if (! data.allStrapiProject.edges[0]) {
        return (<>No Post</>)
    }
    const node = data.allStrapiProject.edges[0].node
    const thumbnail_path = node.thumbnail ? node.thumbnail.childImageSharp.fluid.src : null

    return (
        <Consumer>
            {({store, functions}) => (
                <>
                    <SEO
                        title={node.title + " - 千のおうち"}
                        description={node.summary}
                        // image_uri={store.config.api_uri + node.thumbnail[0].url}
                        image_uri={store.config.api_uri + thumbnail_path}
                    />
                    <div id="article" className="main-content">
                        <PostArticle node={node} />
                    </div>
                </>
            )}
        </Consumer>
    )
}

export default ProjectTemplate

export const pageQuery = graphql`
    query ProjectByURI($uri: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allStrapiProject(
            filter: {
                visible: {eq: true}
                uri: {eq: $uri}
            }
        ) {
            edges {
                node {
                    id
                    title
                    date
                    content
                    uri
                    summary
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 480) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    visible
                }
            }
        }
    }
`
