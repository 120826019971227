import React from "react"
import {Link} from "gatsby"
import {Consumer} from "src/utils/contexts"
import { useSiteMetadata } from "src/utils/use-site-metadata"

import DateCircle from "src/components/Element/date_circle"

import "./index.css"

const PostArticle = ({node}) => {

    const { apiURL } = useSiteMetadata()

    if(node.tags === undefined) {
        node.tags = []
    }

    return (
            <Consumer>
                {({store, functions}) => (
                    <>
                        {(() => {
                            // 本文中の画像のパスを書き換え
                            let content_with_img
                            if (node.content) {
                                content_with_img = node.content.replace(/\/uploads/g, apiURL + "/uploads")
                            } else {
                                content_with_img = node.content
                            }

                            return (
                                <div className="article-main">
                                    <div className="am-wrapper">
                                        <div className="am-header">
                                            <div className="am-date">
                                                <div className="am-date-inner">
                                                    <DateCircle node_date={node.date} />
                                                </div>
                                            </div>
                                            <div className="am-content-title">
                                                <h1>{node.title}</h1>
                                            </div>
                                        </div>
                                        <div id="artcile_content" className="am-content">
                                            <div className="am-content-text"
                                                 dangerouslySetInnerHTML={{__html: content_with_img}}/>
                                            <div className="am-tags" style={node.tags.length === 0 ? {display: "none"} : {}}>
                                                <ul>
                                                    {node.tags.map((tag) => {
                                                        const tag_uri = "/tag/" + tag.Name

                                                        return (
                                                          <li key={tag.id}><Link to={tag_uri}>{tag.Name}</Link></li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })()}
                    </>
                )}
            </Consumer>
    )
}

export default PostArticle
